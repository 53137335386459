export default function NotFound() {

     return (

         <div className="d-flex flex-column align-items-center justify-content-center">

           <h2 className="text-danger fw-700">Page introuvable !</h2>

         </div>

     )
}