/*export const apiBaseUrl = "http://localhost:8000";
export const apiEndPoint = "http://localhost:8000/api/";
export const apiStorage = "http://localhost:8000/upload/img";

/*

export const apiBaseUrl = "https://127.0.0.1:8000";
export const apiEndPoint = "https://127.0.0.1:8000/api/";
export const apiStorage = "https://127.0.0.1:8000/upload/img";
*/
export const apiBaseUrl = "https://api.paramall.tn";
export const apiEndPoint = "https://api.paramall.tn/api/";
export const apiStorage = "https://api.paramall.tn/upload/img";
